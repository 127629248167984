import { BrowserModule, BrowserTransferStateModule, TransferState } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule, Optional } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorInterceptorService } from './app-common/services/error-interceptor/error-interceptor.service';
import { RavenErrorHandler } from './app-common/services/error-handler/raven-error-handler';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorIntlService } from './app-common/services/paginator-intl/paginator-intl.service';
import { COUNTRIES_DATA } from '../countries/countries';
import { AppConfiguration } from './configuration/app.configuration';
import { ConfigKey } from './configuration/config.key';
import { environment } from '../environments/environment';
import { ConfigToken } from './configuration/config.token';
import { TranslocoRootModule } from './transloco-root.module';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { LobbyModule } from './page-modules/lobby/lobby.module';
import { AccessTokenInterceptor } from './app-common/interceptor/access-token.interceptor';
// import { DashboardModule } from './desk/modules/dashboard/dashboard.module';
// import { DeskModule } from './desk/desk.module';
import { StoreModule } from '@ngrx/store';
import { reducers } from '#appState';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AllEffects } from './store/effects';
import { metaReducers } from './store/meta-reducers';
import { SchedulerService } from './scheduler/scheduler.service';
import { AppInitService } from './app-init.service';
import { SetCompanyIdInterceptor } from './app-common/interceptor/set-company-id.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { AnalyticsService } from './app-common/services/analytics/analytics.service';
import { SetKeyThrottlerInterceptor } from './app-common/interceptor/set-key-throttler.interceptor';
import { SocketIoService } from './app-common/services/socket.io/socket.io.service';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

registerLocaleData(localeRu);

declare const require: any;

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    LobbyModule,
    // DeskModule,
    TranslocoRootModule,
    VexModule,
    // DashboardModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    EffectsModule.forRoot([...AllEffects]),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: environment.production,
        })
      : [],
  ],
  providers: [
    CookieService,
    AnalyticsService,
    SocketIoService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: AppInitService) => () => ds.init(),
      deps: [AppInitService, SchedulerService, AnalyticsService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useClass: RavenErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetCompanyIdInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetKeyThrottlerInterceptor,
      multi: true,
    },
    {
      provide: AppConfiguration,
      useFactory: (config: AppConfiguration, state: TransferState) => {
        if (config) {
          // @ts-ignore
          state.set(ConfigKey, config);
        }
        // @ts-ignore
        return new AppConfiguration(state.get(ConfigKey, environment));
      },
      deps: [[new Optional(), ConfigToken], TransferState],
    },
    {
      provide: COUNTRIES_DATA,
      useFactory: () => {
        // todo: Implement dynamic locale
        return require('../countries/countries-ru.json');
      },
    },
    /*{
      provide: LOCALE_ID,
      useValue: 'en_US',
    },*/
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorIntlService,
    },
    DeviceDetectorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
