import {
  IRoleSettingsCompany,
  IUserRoleSettingsEntity,
} from './@models/user/interface/user-role-settings.entity.interface';
import { DocumentDataType, InvoiceDataType } from './@models/document-data/document-data.types';
import { ITaskPrice } from './@models/task/task-price.interface';
import { ITaskPricePart } from './@models/task/task-price-part.interface';
import { ITransactionEntity } from './@models/transaction/interface/transaction.entity.interface';
import { ICompanyEntity } from './@models/company/interface/company.entity.interface';
import { ICompanyPublicInfoEntity } from './@models/company/interface/company-public-info.entity.interface';
import { ICreateCompanyDto } from './@models/company/interface/create-company.dto.interface';
import { IOIPEntity } from './@models/oip/interface/oip.entity.interface';
import { IUserEntity } from './@models/user/interface/user.entity.interface';
import { IGetManyDefaultResponse } from './@models/_shared/interface/get-many-default-response.interface';
import { IServiceRequestEntity } from './@models/service-request/interface/service-request.entity.interface';
import { IUserMeRes } from './@models/user/interface/user-me-res.interface';
import {
  IJwtDto,
  IUserSignInRes,
  IUserSignInResDto,
  IUserSignInSocialResDto,
} from './@models/user/interface/user-sign-in-res.dto.interface';
import { IServiceEntity } from './@models/service/interface/service.entity.interface';
import { IServiceCategoryEntity } from './@models/service/interface/service-category.entity.interface';
import {
  ICompanyServiceEntity,
  ICompanyServiceListNG,
} from './@models/company/interface/company-service.entity.interface';
import { IAddTrustedCompanyDto } from './@models/company/interface/add-trusted-company.dto.interface';
import { IResCompanyCollaborators } from './@models/company/interface/res-company-collaborators.interface';
import { IRIA } from './@models/ria/ria.interface';
import { IChatEntity } from './@models/chat/interface/chat.entity.interface';
import { ITaskEntity } from './@models/task/interface/task.entity.interface';
import { IFileEntity, IFolderFileEntity } from './@models/file/interface/file.entity.interface';
import { fileOrders, IFileSortSelect, IPublicFile } from './@models/file/file.interface';
import { IFeedbackEntity } from './@models/company/interface/feedback.entity.interface';
import { IUpdateFeedbackVerifiedDto } from './@models/company/interface/update-feedback-verified.dto.interface';
import { ICounterpartyEntity } from './@models/counterparty/interface/counterparty.entity.interface';
import { IUserPasswordRecoveryPostDto } from './@models/user/interface/user-password-recovery.dto.interface';
import { IUserSignInAdminDto, IUserSignInDto } from './@models/user/interface/user-sign-in.dto.interface';
import { IOfferEntity } from './@models/service-request/interface/offer.entity.interface';
import { IUserSignUpResDto } from './@models/user/interface/user-sign-up-res.dto.inerface';
import { IUserEmailChangeDto } from './@models/user/interface/user-email-change.dto.interface';
import { IUserPasswordChangeDto } from './@models/user/interface/user-password-change.dto.interface';
import {
  IUserEmailConfirmationDto,
  IUserEmailConfirmationRes,
} from './@models/user/interface/user-email-confirmation.dto.interface';
import { IUserSignUpDto } from './@models/user/interface/user-sign-up.dto.interface';
import { IUserUpdateDto } from './@models/user/interface/user-update.dto.interface';
import { IReasonEntity } from './@models/reason/interface/reason.entity.interface';
import { IOnboardingStepsEntity } from './@models/company/interface/onboarding-steps.entity.interface';
import { IAnalyticsDefault, IAnalyticsUser } from './@models/_shared/interface/analytics.interface';
import { RequestCreatingTaskFromFileInterface } from './@models/task/interface/request-creating-task-from-file.interface';
import { ResponseCreatingTaskFromFileInterface } from './@models/task/interface/response-creating-task-from-file.interface';
import { IResponseCreatingTaskFromTempUser } from './@models/task/interface/response-creating-task-from-temp-user.interface';
import {
  OipStatsCountriesInterface,
  OipStatsInterface,
  OipStatsStageInterface,
  OipStatsTypeInterface,
  OipStatsYearsInterface,
} from './@models/oip/interface/oip-stats.interface';
import { XlsxOip } from './@models/oip/interface/oip-xlsx.interface';
import { ResponseCreatingReminderFromFileInterface } from './@models/reminder/interface/response-creating-reminder-from-file.interface';
import { RequestCreatingReminderFromFileInterface } from './@models/reminder/interface/request-creating-reminder-from-file.interface';
import { ITempFile } from './@models/file/interface/temp-file.interface';
import { IReminderEntity } from './@models/reminder/interface/reminder.entity.interface';
import { IProductEntity } from './@models/product/interface/product.entity.interface';
import { IPostEntity } from './@models/company/interface/post.entity.interface';
import { ChatMessage } from './@models/chat/interface/message.entity.interface';
import { IValidationError } from './@models/_shared/interface/validation-error.interface';

import { TaskAdditionalInformationInterface } from './@models/task/interface/task-additional-information.interface';
import { IPriceEntity } from './@models/price/interface/price.entity.interface';
import { IPriceItemEntity } from './@models/price/interface/price-item.entity.interface';
import {
  AdminDBSettingsInterface,
  AdminSettingsInterface,
} from './@models/admin-settings/interface/admin-settings.interface';
import { PatseerModuleOptions } from './@models/patseer/interfaces/patseer-module-options.interface';
import {
  MarkifySearchOptions,
  MarkifySearchTrademarkOptions,
} from './@models/markify/interfaces/markify-search-options.interface';
import { MarkifyModuleOptions } from './@models/markify/interfaces/markify-module-options.interface';
import { IPurchasedServiceEntity } from './@models/company/interface/purchased-service.interface';
import { IShortUrlEntity } from './@models/short-url/interface/short-url.entity.interface';
import { AiCreateTaskInterface } from './@models/ai-chat/interface/ai-create-task.interface';
import { ICouponEntity } from './@models/coupon/interfaces/coupon.interface';
import { IOipGoodsAndServicesEntity } from './@models/oip/interface/oip-goods-and-services.entity.interface';
import { INormalizedIpRight } from './@models/_shared/interface/normalized-ip-right.interface';
import { IOipTrademarkLinkEntity } from './@models/oip/interface/oip-trademark-link-entity.interface';
import { IUpdateLogRecordEntity } from './@models/update-log-record/interface/update-log-record.entity.interface';
import { IPrecalculatedPriceResInterface } from './@models/price/interface/precalculated-price-res.interface';
import { ITechAreaCategoryEntity } from './@models/technology-area/interface/tech-area-category.interface';
import { ITechAreaEntity } from './@models/technology-area/interface/tech-area.interface';
import { IArea } from './@models/company/interface/area.entity.interface';
import { IGenerateAiResultRes } from './@models/markify/interfaces/generate-ai-result-res.interface';
import { IMarkifyDBsRecencyEntity } from './@models/markify/interfaces/markify-dbs-recency.entity.interface';
import { ITrademarkWatchEntity } from './@models/trademark-watch/interfaces/trademark-watch.interface';
import { ITrademarkWatchHistoryEntity } from './@models/trademark-watch-history/interfaces/trademark-watch-history.interface';
import { IDexatelCreateVerificationRes } from './@models/dexatel/interfaces/dexatel-create-verification-res.interface';
import { DexatelModuleOptions } from './@models/dexatel/interfaces/dexatel-module-options.interface';
import { IDexatelFlashCallDataRes } from './@models/dexatel/interfaces/dexatel-flash-call-data-response.interface';
import { IVerifyCodeResponse } from './@models/code-verification/interface/verify-code-response.interface';
import { ISendVerificationCodeResponse } from './@models/code-verification/interface/send-verification-code-response.interface';
import { ISendVerificationCodeRequest } from './@models/code-verification/interface/send-verification-code-request.interface';
import { IPhone } from './@models/user/interface/phone.interface';
import { IVerifyCodeRequest } from './@models/code-verification/interface/verify-code-request.interface';
import { ITempAdditionalFields } from './@models/company/interface/temp-additional-fields.interface';
import { IStripeModuleOptions } from './@models/_shared/interface/stripe-module-options.interface';
import { ICompanyAdditionalFieldsEntity } from './@models/company/interface/company-additional-fields.entity.interface';
import { IDiscordModuleOptions } from './@models/_shared/interface/discord-module-options.interface';
import { ITwilioModuleOptions } from './@models/twilio/interfaces/twilio-module-options.interface';
import { ISearchLogsResponseResultSearch } from './@models/search-logs/interface/search-logs-response.interface';
import { IInvitationRewardSettingsEntity } from './@models/user/interface/invitation-reward-settings.entity.interface';
import { IActionEntity } from './@models/action/interface/action.entity.interface';
import { ICompanyTriggerEntity } from './@models/company-trigger/interface/company-trigger.entity.interface';
import { ITriggerEntity } from './@models/trigger/interface/trigger.entity.interface';
import { TelegramModuleOptions } from './@models/telegram/interfaces/telegram-module-options.interface';
import { ICurrencyResItem } from './@models/ip-fees/interfaces/currency-res.interface';
import { IJurisdictionResItem } from './@models/ip-fees/interfaces/jurisdiction-res.interface';
import { IAgentStateChannels } from './@models/ai-chat/interface/agent-state-channels.interface';
import { FileTagInitValue } from './@models/file/interface/file-tag-init-value';

export * from './@models/markify/interfaces/markify-response.interface';
export * from './@models/patseer/interfaces/patseer-response.interface';
export * from './@models/dexatel/interfaces/dexatel-create-verification-body.interface';
export * from './@models/ip-fees/interfaces/parameters-res.interface';
export * from './@models/ip-fees/interfaces/calculate-res.interface';
export * from './@models/ip-fees/interfaces/calculate-body.interface';
export * from './@models/deals/interface/checkbox-params';

export {
  AdminDBSettingsInterface,
  AdminSettingsInterface,
  AiCreateTaskInterface,
  ChatMessage,
  DocumentDataType,
  IAddTrustedCompanyDto,
  IAnalyticsDefault,
  IAnalyticsUser,
  IArea,
  IChatEntity,
  ICompanyEntity,
  ICompanyPublicInfoEntity,
  ICompanyServiceEntity,
  ICompanyServiceListNG,
  ICounterpartyEntity,
  ICouponEntity,
  ICreateCompanyDto,
  IFeedbackEntity,
  IFileEntity,
  IFileSortSelect,
  IFolderFileEntity,
  IGenerateAiResultRes,
  IGetManyDefaultResponse,
  IJwtDto,
  IMarkifyDBsRecencyEntity,
  INormalizedIpRight,
  IOIPEntity,
  IOfferEntity,
  IOipGoodsAndServicesEntity,
  IOipTrademarkLinkEntity,
  IOnboardingStepsEntity,
  IPostEntity,
  IPrecalculatedPriceResInterface,
  IPriceEntity,
  IPriceItemEntity,
  IProductEntity,
  IPublicFile,
  IPurchasedServiceEntity,
  IRIA,
  IReasonEntity,
  IReminderEntity,
  IResCompanyCollaborators,
  IResponseCreatingTaskFromTempUser,
  IRoleSettingsCompany,
  IServiceCategoryEntity,
  IServiceEntity,
  IServiceRequestEntity,
  IShortUrlEntity,
  ITaskEntity,
  ITaskPrice,
  ITaskPricePart,
  ITechAreaCategoryEntity,
  ITechAreaEntity,
  ITempFile,
  ITrademarkWatchEntity,
  ITrademarkWatchHistoryEntity,
  ITransactionEntity,
  IUpdateFeedbackVerifiedDto,
  IUpdateLogRecordEntity,
  IUserEmailChangeDto,
  IUserEmailConfirmationDto,
  IUserEmailConfirmationRes,
  IUserEntity,
  IUserMeRes,
  IUserPasswordChangeDto,
  IUserPasswordRecoveryPostDto,
  IUserRoleSettingsEntity,
  IUserSignInAdminDto,
  IUserSignInDto,
  IUserSignInRes,
  IUserSignInResDto,
  IUserSignInSocialResDto,
  IUserSignUpDto,
  IUserSignUpResDto,
  IUserUpdateDto,
  IValidationError,
  InvoiceDataType,
  MarkifyModuleOptions,
  MarkifySearchOptions,
  MarkifySearchTrademarkOptions,
  OipStatsCountriesInterface,
  OipStatsInterface,
  OipStatsStageInterface,
  OipStatsTypeInterface,
  OipStatsYearsInterface,
  PatseerModuleOptions,
  RequestCreatingReminderFromFileInterface,
  RequestCreatingTaskFromFileInterface,
  ResponseCreatingReminderFromFileInterface,
  ResponseCreatingTaskFromFileInterface,
  TaskAdditionalInformationInterface,
  XlsxOip,
  ITempAdditionalFields,
  fileOrders,
  DexatelModuleOptions,
  IDexatelCreateVerificationRes,
  IDexatelFlashCallDataRes,
  IVerifyCodeResponse,
  ISendVerificationCodeResponse,
  ISendVerificationCodeRequest,
  IPhone,
  IVerifyCodeRequest,
  IStripeModuleOptions,
  IDiscordModuleOptions,
  ICompanyAdditionalFieldsEntity,
  ITwilioModuleOptions,
  ISearchLogsResponseResultSearch,
  IInvitationRewardSettingsEntity,
  IActionEntity,
  ICompanyTriggerEntity,
  ITriggerEntity,
  TelegramModuleOptions,
  ICurrencyResItem,
  IJurisdictionResItem,
  IAgentStateChannels,
  FileTagInitValue,
};
